<template>
  <div class="header" :style="getBackgroundImg">
    <div class="headerbox">
      <div class="header-router">
        <span @click="tohome" style="cursor: pointer">{{ Language.firstHeader.home }}</span>
        >
        <span style="color: var(--theme-color1);cursor: pointer" @click="reflesh">{{ title }}</span>
      </div>
      <h1 style="font-size: 50px;color: #FFFFFF" @click="reflesh">{{ title }}</h1>
      <div class="header-text">
        {{ Language.firstHeader.msg1 }}
      </div>
      <div class="btn-contactUs" @click="tocontactUs">{{ Language.firstHeader.Contact_Us }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'firstHeader',
  inject: ['reload', 'L'],
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    imgUrl: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    getBackgroundImg() {
      return {
        backgroundImage: 'url(' + require('../assets/img/' + this.imgUrl + '/bg.jpg') + ')'
      }
    },
    Language() {
      return this.L()
    }
  },
  methods: {
    tohome() {
      this.$router.replace("/")
    },
    tocontactUs() {
      this.$router.push("/contact")
    },
    reflesh() {
      this.reload()
    },
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: 480px;
  color: #ffffff;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: -70.5px;

  .headerbox {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 20px;
    padding-top: 150px;

  }

  .header-router {
    margin-bottom: 36px;
    font-size: 14px;
  }

  .header-text {
    margin: 18px 0 36px;
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 1920px) {
  .header{
    background-size: auto 100%;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
}
@media (min-width: 0px) and (max-width: 800px){
  .headerbox{
    .header-router{
      margin-bottom: 0px
    }
    .header-text{
      margin: 18px 0 18px;
      font-size: 16px;
    }
    h1{
      margin-bottom: 0px;
      font-size: 40px !important;
    }
  }
}
</style>

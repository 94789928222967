<template>
  <div style="background-color: #faf9f9">
    <MyHead :nav="3"></MyHead>
    <FirstHeader :title="Language.managemenSystem.title" img-url="managementSystem"/>
    <div class="content1">
      <div class="content1-title">{{ Language.managemenSystem.title }}</div>
      <div class="content1-text" style="margin: 40px 0 32px;">
        {{Language.managemenSystem.message1}}
      </div>
      <div class="content1-text">
        {{Language.managemenSystem.message2}}
      </div>
    </div>
    <div style="background-color: #ffffff;">
      <div class="content2">
        <div class="text">
          <div class="content2-title">{{Language.managemenSystem.stack}}</div>
          <div class="content2-text">
            {{Language.managemenSystem.message3}}
          </div>
          <div class="content2-text">{{Language.managemenSystem.message4}}</div>
          <div class="btn-learnMore" @click="toqualityStack(1)">{{Language.managemenSystem.LearnMore}}</div>
        </div>
        <div class="content2-img">
          <img class="imgStyle" src="../assets/img/managementSystem/ZLZ.png" alt/>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="text show">
        <div class="content2-title">{{Language.managemenSystem.chain}}</div>
        <div class="content2-text">
          {{Language.managemenSystem.message5}}
        </div>
        <div class="btn-learnMore" @click="toqualityStack(2)">{{Language.managemenSystem.LearnMore}}</div>
      </div>
      <div class="content2-img">
        <img class="imgStyle" src="../assets/img/managementSystem/ZLZ.png" alt/>
      </div>
      <div class="text hide">
        <div class="content2-title">{{Language.managemenSystem.chain}}</div>
        <div class="content2-text">
          {{Language.managemenSystem.message5}}
        </div>
        <div class="btn-learnMore" @click="toqualityStack(2)">{{Language.managemenSystem.LearnMore}}</div>
      </div>
    </div>
    <div style="background-color: #ffffff">
      <div class="content3">
        <div class="content3-title">{{Language.managemenSystem.OurServices}}</div>
        <div class="content3-text">{{Language.managemenSystem.message6}}</div>
        <!--面向企业-->
        <div class="content3-record">
          <div class="record-title">
            <div class="flex">
              <div style="width: 40px; height: 40px;">
                <img class="imgStyle" src="../assets/img/managementSystem/enterprise.png" alt />
              </div>
              <div class="title-text">{{Language.managemenSystem.EnterpriseOriented}}</div>
            </div>
            <div class="btn-learnMore" style="margin-top: 0" @click="tocontactUs">{{ Language.managemenSystem.ContactUs}}</div>
          </div>
          <div class="record-introduce">
            {{Language.managemenSystem.message7}}
          </div>
          <div class="record-list">
            <ul>
              <li>{{Language.managemenSystem.message8}}</li>
              <li>{{Language.managemenSystem.message9}}</li>
              <li>{{Language.managemenSystem.message10}}</li>
            </ul>
          </div>
        </div>
        <!--面向贸易商-->
        <div class="content3-record">
          <div class="record-title">
            <div class="flex">
              <div style="width: 40px; height: 40px;">
                <img class="imgStyle" src="../assets/img/managementSystem/user.png" alt />
              </div>
              <div class="title-text">{{Language.managemenSystem.TargetingTraders}}</div>
            </div>
            <div class="btn-learnMore" style="margin-top: 0" @click="tocontactUs">{{Language.managemenSystem.ContactUs}}</div>
          </div>
          <div class="record-introduce">
            {{Language.managemenSystem.message11}}
          </div>
          <div class="record-list">
            <ul>
              <li>{{Language.managemenSystem.message12}}</li>
              <li>{{Language.managemenSystem.message13}}</li>
              <li>{{Language.managemenSystem.message14}}</li>
            </ul>
          </div>
        </div>
        <!--面向零售商-->
        <div class="content3-record">
          <div class="record-title">
            <div class="flex">
              <div style="width: 40px; height: 40px;">
                <img class="imgStyle" src="../assets/img/managementSystem/team.png" alt />
              </div>
              <div class="title-text">{{Language.managemenSystem.TargetingRetailers}}</div>
            </div>
            <div class="btn-learnMore" style="margin-top: 0" @click="tocontactUs">{{Language.managemenSystem.ContactUs}}</div>
          </div>
          <div class="record-introduce">
            {{Language.managemenSystem.message15}}
          </div>
          <div class="record-list">
            <ul>
              <li>{{Language.managemenSystem.message16}}</li>
              <li>{{Language.managemenSystem.message17}}</li>
              <li>{{Language.managemenSystem.message18}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="content4-title">{{Language.managemenSystem.OurStrengths}}</div>
      <div class="content4-text" style="margin-top: 16px;">
        {{Language.managemenSystem.message19}}
      </div>
      <div class="content4-text">
        {{Language.managemenSystem.message20}}
      </div>
      <div class="flex" style="margin-top: 30px;">
        <div class="module">
          <div class="module-img">
            <img class="imgStyle" src="../assets/img/managementSystem/share.png" alt />
          </div>
          <div class="module-title">{{Language.managemenSystem.RealTimeSharing}}</div>
          <div class="module-text">{{Language.managemenSystem.message21}}</div>
          <div class="module-text">{{Language.managemenSystem.message22}}</div>
        </div>
        <div class="module">
          <div class="module-img">
            <img class="imgStyle" src="../assets/img/managementSystem/standard.png" alt />
          </div>
          <div class="module-title">{{Language.managemenSystem.UnifiedStandards}}</div>
          <div class="module-text">{{Language.managemenSystem.message23}}</div>
          <div class="module-text">{{Language.managemenSystem.message24}}</div>
        </div>
        <div class="module">
          <div class="module-img">
            <img class="imgStyle" src="../assets/img/managementSystem/consensus.png" alt />
          </div>
          <div class="module-title">{{Language.managemenSystem.MultiPartyConsensus}}</div>
          <div class="module-text">{{Language.managemenSystem.message25}}</div>
          <div class="module-text">{{Language.managemenSystem.message26}}</div>
        </div>
        <div class="module">
          <div class="module-img">
            <img class="imgStyle" src="../assets/img/managementSystem/security.png" alt />
          </div>
          <div class="module-title">{{Language.managemenSystem.SafeAndReliable}}</div>
          <div class="module-text">{{Language.managemenSystem.message27}}</div>
          <div class="module-text">{{Language.managemenSystem.message28}}</div>
        </div>
        <div class="module">
          <div class="module-img">
            <img class="imgStyle" src="../assets/img/managementSystem/system.png" alt />
          </div>
          <div class="module-title">{{Language.managemenSystem.systemManagement}}</div>
          <div class="module-text">{{Language.managemenSystem.message29}}</div>
          <div class="module-text">{{Language.managemenSystem.message30}}</div>
        </div>
        <div class="module">
          <div class="module-img">
            <img class="imgStyle" src="../assets/img/managementSystem/map.png" alt />
          </div>
          <div class="module-title">{{Language.managemenSystem.QualityMap}}</div>
          <div class="module-text">{{Language.managemenSystem.message31}}</div>
          <div class="module-text">{{Language.managemenSystem.message32}}</div>
        </div>
      </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import FirstHeader from "@/component/firstHeader";
import MyHead from "../component/myhead"
import Myfoot from "@/component/myfoot";
export default {

  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },

  components: {
    FirstHeader,
    MyHead,
    Myfoot
  },

  methods:{
    toqualityStack(type){
      if(type == 1){
        this.$router.push("/quality-control-management/quality-road")
      }
      if(type == 2){
        this.$router.push("/quality-control-management/quality-chain")
      }
    },
    tocontactUs(){
      this.$router.push("/contact")
    },
  }
}
</script>
<style lang="scss" scoped>

.show{
  display: none;
}

.hide{
  display: block;
}

.content1 {
  width: 1200px;
  margin: 0 auto;
  padding: 80px 0 120px;

  .content1-title {
    font-size: 42px;
    color: var(--title-color);
    text-align: center;
  }

  .content1-text {
    color: var(--text-color);
    line-height: 28px;
  }
}

.content2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text{
    width: calc(50% - 20px);
  }
  .content2-title {
    font-size: 42px;
    color: var(--title-color);
    margin-bottom: 20px;
  }

  .content2-text {
    color: var(--text-color);
    line-height: 28px;
  }

  .content2-img {
    width: 50%;
  }
}

.content3 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;

  .content3-title {
    font-size: 42px;
    color: var(--title-color);
    text-align: center;
  }

  .content3-text {
    color: var(--text-color);
    text-align: center;
    line-height: 28px;
    margin: 16px 0 40px;
  }

  .content3-record {
    border-bottom: 1px solid #e8e8e8;

    .record-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 24px 0 20px;

      .title-text {
        font-size: 30px;
        color: var(--title-color);
        margin-left: 20px;
      }
    }

    .record-introduce {
      color: var(--text-color);
      line-height: 28px;
    }

    .record-list ul {
      padding-inline-start: 20px;
      margin-block-start: 8px;
      margin-block-end: 24px;
    }

    .record-list ul li {
      color: var(--text-color);
      line-height: 28px;
    }

    .record-list ul li::marker {
      color: var(--theme-color1);
    }
  }

  .content3-record:last-child {
    border-bottom: none;
  }
}

.content4 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 120px;

  .content4-title {
    font-size: 42px;
    color: var(--title-color);
    text-align: center;
  }

  .content4-text {
    color: var(--text-color);
    line-height: 28px;
    text-align: center;
  }
  .flex{
    flex-wrap: wrap;
  }
  .module {
    text-align: center;
    width: 340px;
    background-color: #ffffff;
    padding: 50px 30px;
    border-radius: 24px;
    color: var(--title--color);
    margin-bottom: 20px;
    .module-img {
      width: 44px;
      height: 44px;
      margin: auto;
    }

    .module-title {
      font-size: 18px;
      font-weight: bold;
      margin: 16px 0 8px;
    }

    .module-text {
      line-height: 22px;
      font-size: 14px;
    }
  }
}


@media (min-width: 0px) and (max-width: 1200px){
  .show{
    display: block;
  }

  .hide{
    display: none;
  }

  .content1{
    width: 100%;
    .content1-text{
      padding: 0px 20px;
    }
  }

  .content2{
    padding: 30px 20px;
    display: block;
    .text{
      width: 100%;
      text-align: center;
    }
    .btn-learnMore{
      margin: 20px auto;
    }
    .content2-img{
      margin: auto;
      width: 100%;
    }
  }

  .content3{
    padding: 50px 20px;
  }

  .content4{
    padding: 50px 20px;
  }
}
@media (min-width: 0px) and (max-width: 1060px){
  .content4{
    .module{
      width: 48%;
    }
  }
}
@media (min-width: 0px) and (max-width: 750px){
  .content4{
    .module{
      width: 100%;
    }
  }
}

</style>
